import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogoSection from '../components/LogoSection';
import { addReaderToConnectedAccountIfNeeded, getToken, uploadFileToStripe } from '../redux/actions';

const POSTerminalSetup = () => {
    const dispatch = useDispatch();
    const [stripeTerminalCode, setStripeTerminalCode] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState(null);
    const clientAccountId = useSelector((state) => state.clientAccountId);
    const readers = useSelector((state) => state.readers);
    const awaitingReaderResponse = useSelector((state) => state.awaitingReaderResponse);
    const fileUploadInProgress = useSelector((state) => state.fileUploadInProgress);

    React.useEffect(() => {
        if (clientAccountId) {
            dispatch(getToken());
        }
    }, [clientAccountId, dispatch]);

    const handleAddReader = () => {
        dispatch(addReaderToConnectedAccountIfNeeded(stripeTerminalCode, clientAccountId));
    };

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFileUpload = () => {
        if (selectedFile) {
            dispatch(uploadFileToStripe(selectedFile));
        }
    };

    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <LogoSection />
                <h1 className="text-2xl font-bold mb-4">WisePOS Terminal Setup</h1>

                <div className="w-full">
                    <label htmlFor="stripeTerminalCode" className="block text-lg font-medium text-gray-700">
                        Stripe Terminal Code
                    </label>
                    <input
                        type="text"
                        id="stripeTerminalCode"
                        name="stripeTerminalCode"
                        value={stripeTerminalCode}
                        onChange={(e) => setStripeTerminalCode(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </div>
                <button
                    onClick={handleAddReader}
                    className={`px-4 py-2 text-white rounded text-xl w-full ${awaitingReaderResponse ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'}`}
                    disabled={awaitingReaderResponse}
                >
                    {awaitingReaderResponse ? 'Connecting...' : 'Connect Terminal'}
                </button>
                {readers.length > 1 && (
                    <div className="w-full text-center mt-4">
                        <p className="text-lg font-medium text-gray-700">
                            Number of connected readers: {readers.length}
                        </p>
                    </div>
                )}

                <div className="w-full mt-8">
                    <h2 className="text-xl font-bold mb-4">Upload Splash Screen</h2>
                    <div className="space-y-4">
                        <input
                            type="file"
                            accept="image/jpeg,image/png"
                            onChange={handleFileSelect}
                            className="w-full"
                        />
                        <button
                            onClick={handleFileUpload}
                            disabled={!selectedFile || fileUploadInProgress}
                            className={`w-full px-4 py-2 text-white rounded ${!selectedFile || fileUploadInProgress
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-blue-500 hover:bg-blue-600'
                                }`}
                        >
                            {fileUploadInProgress ? 'Uploading...' : 'Upload Splash Screen'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default POSTerminalSetup; 
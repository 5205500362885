import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settingsIcon from '../images/settings01.png';

const LogoSection = () => {
    const logoURL = useSelector((state) => state.logoURL);
    const logoLoading = useSelector((state) => state.logoLoading);

    return (
        <div className="w-full flex items-center justify-between p-2 bg-gray-100" style={{ height: '75px' }}>
            {logoLoading ? (
                <div className="h-15 w-40 animate-pulse bg-gray-300 rounded"></div>
            ) : (
                <Link to="/">
                    <img
                        src={logoURL}
                        alt="Logo"
                        className="h-15"
                        style={{ width: "auto", height: '60px' }}
                        onError={(e) => {
                            e.target.style.display = 'none';
                            localStorage.removeItem('logoURL');
                        }}
                    />
                </Link>
            )}
            <Link to="/settings" className="h-6 w-6">
                <img src={settingsIcon} alt="Settings" className="h-full w-full" />
            </Link>
        </div>
    );
};

export default LogoSection; 
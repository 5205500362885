import { app, auth, provider, signInWithPopup, db } from '../firebase/firebase.utils';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, getDocs, collection, onSnapshot, addDoc, setDoc } from "firebase/firestore";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, sendPasswordResetEmail
} from 'firebase/auth';

export const increment = () => ({
    type: 'counter/increment',
});

export const clearUserDetails = () => ({
    type: 'trevor/clearUserDetails',
    payload: {}
});

export const decrement = () => ({
    type: 'counter/decrement',
});

export const setTargetFundraisingAction = (id) => ({
    type: 'user/userSelectedFundraisingTarget',
    payload: id
});


export const setUserEmail = (email) => ({
    type: 'user/setUserEmail',
    payload: email
});


export const setUserDetails = (userDetails) => {
    return async (dispatch) => {
        console.log('Inside Dispatch of user details', userDetails)
        dispatch({
            type: 'trevor/setUserDetails',
            payload: userDetails
        });
        //console.log('Now getting URL for user dashboard')
        //dispatch(getURLFromTrevorForUserDetails());
    }
}



    ;

export const listenForAuthChanges = () => {
    console.log('listening for auth changes')
    return (dispatch) => {
        auth.onAuthStateChanged(async auth => {
            console.log('auth', auth)
            if (auth) {

                const loggedInUser = { uid: auth.uid, displayName: auth.displayName, email: auth.email };
                console.log('User trying to log in', loggedInUser);
                const docRef = doc(db, "allowedUsers", loggedInUser.email);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    console.log('User is in allowedUsers collection')
                    dispatch({
                        type: 'user/signIn',
                        payload: loggedInUser
                    });
                } else {
                    console.log('User is not in allowedUsers collection');
                    dispatch(signOut());
                }








            } else {
                console.log('User is NOT signed in');
                dispatch({
                    type: 'user/signOut'
                });
            }
        });
    };
};

export const fetchAllowedUsers = (tenantId) => {
    return async (dispatch) => {
        try {
            const querySnapshot = await getDocs(collection(db, "allowedUsers"));
            const users = [];

            querySnapshot.forEach((doc) => {
                const userData = doc.data();

                users.push({
                    id: doc.id,
                    email: doc.id, // email is the document ID
                    role: userData.role,
                    tenantID: userData.tenantID
                });

            });

            dispatch({
                type: 'tenant/setAllowedUsers',
                payload: users
            });

            return users;
        } catch (error) {
            console.error("Error fetching allowed users:", error);
            return [];
        }
    };
};

export const signInWithGoogle = (callback) => {
    return (dispatch) => {
        console.log('Clicked Sign In with Google');
        signInWithPopup(auth, provider)
            .then(async (result) => {
                console.log('User is signed in with Google', result.user);
                const user = result.user;
                const loggedInUser = { uid: user.uid, displayName: user.displayName, email: user.email };
                const docRef = doc(db, "allowedUsers", loggedInUser.email);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    console.log('User is in allowedUsers collection');
                    dispatch({
                        type: 'user/signIn',
                        payload: loggedInUser, // Dispatch only serializable data
                    });
                    if (callback) callback();  // Execute callback if provided
                } else {
                    console.log('User is not in allowedUsers collection');
                    dispatch(signOut());
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const signOut = () => {
    return (dispatch) => {
        localStorage.removeItem('logoURL');
        auth.signOut().then(() => {
            dispatch({
                type: 'user/signOut'
            });
        });
    };
}



export const processPOSPaymentAction = (details) => {

    const amount = parseFloat(details.amount.replace(/[$,]/g, ''));
    const email = details.email ? details.email : 'pos@infq.cc';
    const campaign_id = details.targetID;
    const isCause = details.isCause;
    console.log('Sending Payment of $', details.amount, ' to POS for donor:', details.email, ' for target:', campaign_id);





    return async (dispatch, getState) => {
        dispatch({
            type: 'user/setAwaitingPOSResponse',
            payload: true
        });
        const state = getState();
        const functions = getFunctions();

        const processPOSPayment = httpsCallable(functions, 'processPOSPaymentConnectUpdatedV2');
        // let clientAccountId = 'acct_1P3FQzK1meoYW4sX';
        // let terminalId = 'tmr_Fk1nzwB6LxeKIH';
        // let org_id = 'JHfoMzPFPGEfMLc6N2qo';
        let clientAccountId = '';
        let terminalId = '';
        let org_id = '';
        const tenant_id = state.userTenant;
        const docRef = doc(db, "tenants", tenant_id);
        const docSnap = await getDoc(docRef);
        const location_id = state.readers[0].location;


        if (docSnap.exists()) {
            console.log('Found tenant info in Firestore:', docSnap.data());
            clientAccountId = docSnap.data().clientAccountId;

            org_id = docSnap.data().org_id;
        } else {
            return { status: "Error", message: "Tenant info not found in Firestore" };
        }
        terminalId = state.readers[0].id;

        console.log('Calling POS Payment with', { email: email, amount: amount, clientAccountId: clientAccountId, terminalId: terminalId, org_id: org_id, campaign_id: campaign_id, tenant_id: tenant_id, isCause: isCause })

        try {
            const result = await processPOSPayment(
                {
                    email: email, amount: amount,
                    clientAccountId: clientAccountId,
                    terminalId: terminalId, org_id: org_id,
                    campaign_id: campaign_id,
                    tenant_id: tenant_id,
                    location_id: location_id,
                    isCause: isCause
                });
            console.log(result.data);
            dispatch({
                type: 'user/setPOSResponse',
                payload: result.data
            });

            if (result.data.status === "Payment succeeded") {
                dispatch({
                    type: 'payment/setSuccess',
                    payload: {
                        status: result.data.status,
                        amount: result.data.paymentIntent.amount / 100, // Convert cents to dollars
                        email: result.data.paymentIntent.metadata.receipt_email
                    }
                });
            }
        } catch (error) {
            console.error('Error calling stripe:', error);
        }
    };
};

export const cancelPOSPaymentAction = (amount) => {
    console.log('Cancelling Payment POS')

    return async (dispatch, getState) => {
        const state = getState();


        let terminalId = '';

        const tenant_id = state.userTenant;
        const docRef = doc(db, "tenants", tenant_id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log('Found tenant info in Firestore:', docSnap.data());
            terminalId = docSnap.data().terminalId;
        } else {
            return { status: "Error", message: "Tenant info not found in Firestore" };
        }
        const functions = getFunctions();
        const cancelPOSPayment = httpsCallable(functions, 'cancelPOSPaymentConnect');

        try {
            const clientAccountId = state.clientAccountId;
            const terminalId = state.readers[0].id;
            const result = await cancelPOSPayment({ clientAccountId: clientAccountId, terminalId: terminalId });
            console.log('Result from cancel POS')
            console.log(result.data);
            dispatch({
                type: 'user/setAwaitingPOSResponse',
                payload: false
            });
        } catch (error) {
            console.error('Error calling stripe cancel POS:', error);
        }
    };
};

function reorderTargets(targets) {
    // Find the position of the "General" target
    const generalPosition = targets.findIndex(target => target.name === "General");

    // If "General" is found, remove it from its current position
    const generalTarget = generalPosition !== -1 ? targets.splice(generalPosition, 1)[0] : null;

    // Sort the targets in alphabetical order
    targets.sort((a, b) => a.name.localeCompare(b.name));

    // If "General" was found, add it back at the beginning
    if (generalTarget) {
        targets.unshift(generalTarget);
    }

    return targets;
}


export const fetchReadersForConnectedAccount = (clientAccountId) => {
    return async (dispatch, getState) => {

        const functions = getFunctions();
        const getReadersForConnectedAccount = httpsCallable(functions, 'getReadersForConnectedAccount');
        const result = await getReadersForConnectedAccount({ clientAccountId: clientAccountId });
        dispatch({ type: 'user/setReaders', payload: result.data.readers?.data });
        const readersArray = result.data.readers?.data;
        const myReader = readersArray.find(reader => reader.label === 'Infaque Reader');
        if (myReader && myReader.status === 'online') {
            dispatch({ type: 'user/setReaderStatus', payload: 'online' });
        } else {
            dispatch({ type: 'user/setReaderStatus', payload: 'offline' });
        }
    };
}

export const fetchTenantID = () => {
    return async (dispatch, getState) => {
        console.log('Fetching Tenant ID');
        const state = getState();
        const userDetails = state.userDetails;
        const email = userDetails.email;

        console.log('Fetching Tenant ID for ', email)
        const docRef = doc(db, "allowedUsers", email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            console.log('Setting Tenant ID in Redux:', docSnap.data().tenantID)
            const userRole = docSnap.data().role;
            const tenantID = docSnap.data().tenantID;

            const logoRef = doc(db, "tenants", tenantID);
            const logoSnap = await getDoc(logoRef);
            console.log('Tenant Data ', logoSnap.data())
            const logoURL = logoSnap.data().logo;
            const clientAccountId = logoSnap.data().clientAccountId;
            const cash_url = logoSnap.data().cash_url || '';
            console.log('Cash URL', cash_url)
            dispatch({
                type: 'user/setCashURL',
                payload: cash_url
            });

            const collectionRef = collection(db, "tenants", tenantID, "fundraisingTargets");
            console.log('Fetching Fundraising Targets for ', tenantID, "fundraisingTargets")

            const querySnapshot = await getDocs(collectionRef);

            // Process each document


            let fundraisingTargetIDs = [];

            // Process each document
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
                fundraisingTargetIDs.push({
                    id: doc.id,
                    name: doc.data().name,
                    shortURL: doc.data().shortURL,
                    cause: doc.data()?.cause === true,
                    code: doc.data()?.code,
                    cash_url: doc.data()?.cash_url
                }); // Add the document ID to the array
            });

            fundraisingTargetIDs = reorderTargets(fundraisingTargetIDs);

            const payload = { tenantID: tenantID, fundraisingTargets: fundraisingTargetIDs };

            await dispatch(fetchReadersForConnectedAccount(clientAccountId));

            await dispatch({
                type: 'user/setUserRole',
                payload: userRole
            });

            await dispatch({
                type: 'user/setTenantID',
                payload: payload
            });

            await dispatch({
                type: 'user/setLogoURL',
                payload: logoURL
            });

            await dispatch({
                type: 'user/setClientAccountId',
                payload: clientAccountId
            });


            dispatch({
                type: 'app/setReady',
                payload: true
            });

        } else {
            console.log("No such document!");
        }
    };
};

export const checkPOSStatusAction = () => {
    console.log('Checking POS Status')

    return async (dispatch, getState) => {
        const state = getState();
        let terminalId = '';
        dispatch({
            type: 'user/setAwaitingPOSStatusResponse',
            payload: true

        })

        const tenant_id = state.userTenant;
        const docRef = doc(db, "tenants", tenant_id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log('Found tenant info in Firestore:', docSnap.data());
            terminalId = docSnap.data().terminalId;
        } else {
            return { status: "Error", message: "Tenant info not found in Firestore" };
        }

        const functions = getFunctions();
        const checkPOSStatus = httpsCallable(functions, 'checkPOSStatus');

        try {
            const result = await checkPOSStatus({ terminalId: terminalId });
            console.log('POS Status:', result.data);
            dispatch({
                type: 'user/setPOSstatus',
                payload: result.data
            });

        } catch (error) {
            console.error('Error calling stripe POS Status:', error);
        }
    };
};

export const addReaderToConnectedAccountIfNeeded = (readerCode, clientAccountId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: 'user/setAwaitingReaderResponse',
            payload: true
        });

        const functions = getFunctions();
        const addReader = httpsCallable(functions, 'AddReaderToConnectedAccountIfNeeded');

        const result = await addReader({ clientAccountId: clientAccountId, readerCode: readerCode });
        console.log('Reader added:', result.data);
        dispatch({
            type: 'user/setReaders',
            payload: result.data.readers
        });
        dispatch({
            type: 'user/setAwaitingReaderResponse',
            payload: false
        });


    };


}

export const startListeningToUserLogs = (userId) => {
    return (dispatch) => {
        const logsRef = collection(db, "allowedUsers", userId, "logs");

        // Return the unsubscribe function
        return onSnapshot(logsRef, (snapshot) => {
            const logs = [];
            snapshot.forEach((doc) => {
                const timestamp = doc.data().timestamp;
                const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);

                logs.push({
                    id: doc.id,
                    ...doc.data(),
                    timestamp: date
                });
            });

            // Sort logs in reverse chronological order
            logs.sort((a, b) => b.timestamp - a.timestamp);

            dispatch({
                type: 'user/setUserLogs',
                payload: logs
            });
        });
    };
};

export const addTenant = (tenant) => {
    return async (dispatch) => {
        console.log('Adding Tenant', tenant)
        try {
            const docRef = doc(db, "tenants", tenant.tenantID);
            await setDoc(docRef, {
                tenantName: tenant.name,
                logo: tenant.logo,
                org_id: tenant.orgID,
                clientAccountId: tenant.clientAccountId
            });
            console.log("Tenant added with ID: ", tenant.tenantID);
            dispatch(fetchTenants()); // Reload the list of tenants
        } catch (e) {
            console.error("Error adding tenant: ", e);
        }
    };
};

export const fetchTenants = () => {
    return async (dispatch) => {
        console.log('Fetching Tenants')
        try {
            const querySnapshot = await getDocs(collection(db, "tenants"));
            const tenants = [];
            querySnapshot.forEach((doc) => {
                console.log('Tenant:', doc.id)
                tenants.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            dispatch({
                type: 'user/setTenants',
                payload: tenants
            });
        } catch (error) {
            console.error("Error fetching tenants:", error);
        }
    };
};

export const changeTenant = (tenantId) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const userEmail = state.userDetails.email;

            // Update the user's tenantID in Firestore
            const userRef = doc(db, "allowedUsers", userEmail);
            await setDoc(userRef, { tenantID: tenantId }, { merge: true });

            console.log('Successfully updated tenant ID to:', tenantId);

            // Redirect to home page and reload
            window.location.href = '/';

        } catch (error) {
            console.error("Error changing tenant:", error);
        }
    };
};

export const refreshTargets = (tenantID) => {
    return async (dispatch, getState) => {



        console.log('Refreshing targets for tenant:', tenantID);

        const functions = getFunctions();
        const refreshTargetsFunction = httpsCallable(functions, 'refreshTargets');

        try {
            const result = await refreshTargetsFunction({ tenantID: tenantID });
            console.log('Refresh targets result:', result.data);


        } catch (error) {
            console.error('Error calling refresh targets:', error);
            return null;
        }

    }
}

export const clearPaymentSuccess = () => ({
    type: 'payment/clearSuccess'
});

export const fetchCompletedPayments = (tenantId) => {
    return async (dispatch) => {
        dispatch({ type: 'payments/setLoading', payload: true });

        try {
            const paymentsRef = collection(db, 'tenants', tenantId, 'completedPayments');
            const querySnapshot = await getDocs(paymentsRef);

            const payments = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                // Convert Firestore Timestamp to JavaScript Date
                const timestamp = data.timestamp?.toDate();

                payments.push({
                    id: doc.id,
                    ...data
                });
            });

            // Sort payments by timestamp in descending order (newest first)
            payments.sort((a, b) => b.timestamp - a.timestamp);

            dispatch({
                type: 'payments/setCompletedPayments',
                payload: payments
            });
        } catch (error) {
            console.error('Error fetching completed payments:', error);
        } finally {
            dispatch({ type: 'payments/setLoading', payload: false });
        }
    };
};

export const startListeningToCompletedPayments = (tenantId) => {
    return (dispatch) => {
        const paymentsRef = collection(db, "tenants", tenantId, "completedPayments");

        // Return the unsubscribe function
        return onSnapshot(paymentsRef, (snapshot) => {
            const payments = [];
            snapshot.forEach((doc) => {
                payments.push({
                    id: doc.id,
                    ...doc.data(),
                    timestamp: doc.data().timestamp?.toDate()
                });
            });

            // Sort payments by timestamp (newest first)
            payments.sort((a, b) => b.timestamp - a.timestamp);

            dispatch({
                type: 'payments/setCompletedPayments',
                payload: payments
            });
        });
    };
};

export const getToken = (clientAccountId) => {
    return async (dispatch) => {
        try {
            const functions = getFunctions();
            const getTokenFunction = httpsCallable(functions, 'getToken');
            const result = await getTokenFunction({ connectedAccountID: clientAccountId });

            if (result.data.status === 'Success') {
                dispatch({
                    type: 'user/setSecretToken',
                    payload: result.data.connectionToken.secret
                });
            } else {
                console.error('Error getting token:', result.data.error);
            }
        } catch (error) {
            console.error('Error calling getToken function:', error);
        }
    };
};

export const uploadFileToStripe = (file) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: 'file/setUploadInProgress', payload: true });
            const state = getState();
            const token = state.secretToken;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('purpose', 'terminal_reader_splashscreen');
            let response;
            console.log('Uploading file to Stripe with token:', token, ' and clientAccountId:', state.clientAccountId)
            try {
                response = await fetch('https://files.stripe.com/v1/files', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Stripe-Account': state.clientAccountId

                    },
                    body: formData
                });
            } catch (error) {
                console.error('Error during file upload to Stripe:', error.message, error.stack);
            }

            const result = await response.json();

            if (result.id) {
                const tenantRef = doc(db, "tenants", state.userTenant);
                await setDoc(tenantRef, {
                    splashScreenImageFile: result.id
                }, { merge: true });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            dispatch({ type: 'file/setUploadInProgress', payload: false });
        }
    };
};

export const setSelectedTargetCode = (code) => ({
    type: 'user/setSelectedTargetCode',
    payload: code
});

export const updateTenant = (tenantId, updates) => {
    return async (dispatch) => {
        try {
            const docRef = doc(db, "tenants", tenantId);
            await setDoc(docRef, updates, { merge: true });
            console.log("Tenant updated successfully");
            dispatch(fetchTenants()); // Refresh the tenants list
        } catch (error) {
            console.error("Error updating tenant:", error);
        }
    };
};

export const addFundraisingTarget = (tenantId, targetData) => {
    return async (dispatch) => {
        try {
            const targetRef = doc(db, "tenants", tenantId, "fundraisingTargets", targetData.id);
            await setDoc(targetRef, {
                name: targetData.name,
                code: targetData.code,
                cause: targetData.cause,
                shortURL: targetData.shortURL
            });
            console.log("Fundraising target added successfully");
            return true;
        } catch (error) {
            console.error("Error adding fundraising target:", error);
            return false;
        }
    };
};

export const addUser = (email) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const tenantID = state.userTenant;

            // Add user to allowedUsers collection
            const userRef = doc(db, "allowedUsers", email.toLowerCase());
            await setDoc(userRef, {
                tenantID: tenantID,
                role: 'user'
            });

            // Refresh the users list
            dispatch(fetchAllowedUsers(tenantID));

            return true;
        } catch (error) {
            console.error("Error adding user:", error);
            return false;
        }
    };
};

export const processCashPayment = (details) => {
    return async (dispatch, getState) => {
        if (!details.email) {
            alert('Email is required for cash donations');
            return;
        }

        if (!details.name) {
            alert('Name is required for cash donations');
            return;
        }

        dispatch({ type: 'payment/setAwaitingCash', payload: true });

        const state = getState();
        const cash_url = state.cash_url;
        const selectedTarget = state.fundraisingTargets[state.selectedFundraisingTarget];
        const selectedTargetCode = state.selectedTargetCode;
        const email = details.email;
        const amount = parseFloat(details.amount.replace(/[$,]/g, ''));

        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0].replace(/-/g, '/');

        const payload = {
            data: [{
                email: email,
                linked_fundraiser: selectedTargetCode,
                name: details.name,
                transaction_amount: amount,
                transaction_channel: "cash",
                transaction_date: formattedDate,
                transaction_tags: ""
            }]
        };

        console.log('Sending Cash Payment to', cash_url)
        console.log('Payload', payload)

        try {
            const response = await fetch(cash_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                dispatch({
                    type: 'payment/setSuccess',
                    payload: {
                        status: "Cash payment recorded",
                        amount: amount,
                        email: email
                    }
                });
            }
        } catch (error) {
            console.error('Error processing cash payment:', error);
        } finally {
            dispatch({ type: 'payment/setAwaitingCash', payload: false });
        }
    }
};

export const signUpWithEmailPassword = (email, password) => {
    return async (dispatch) => {
        try {
            // First create the user account


            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            try {
                // Then check if they're in allowedUsers
                const docRef = doc(db, "allowedUsers", user.email.toLowerCase());
                const docSnap = await getDoc(docRef);
            } catch (error) {
                console.error("Error in signUpWithEmailPassword:", error);
                await auth.signOut();
                return { success: false, error: error.message };
            }



            dispatch({
                type: 'user/signIn',
                payload: {
                    uid: user.uid,
                    email: user.email
                }
            });

            return { success: true };
        } catch (error) {
            console.error("Error in signUpWithEmailPassword:", error);
            return { success: false, error: error.message };
        }
    };
};

export const signInWithEmailPassword = (email, password) => {
    return async (dispatch) => {
        try {
            // Check if email exists in allowedUsers

            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            try {
                // Then check if they're in allowedUsers
                const docRef = doc(db, "allowedUsers", user.email.toLowerCase());
                const docSnap = await getDoc(docRef);
            } catch (error) {
                console.error("Error in signInWithEmailPassword:", error);
                await auth.signOut();
                return { success: false, error: error.message };
            }

            dispatch({
                type: 'user/signIn',
                payload: {
                    uid: user.uid,
                    email: user.email
                }
            });

            return { success: true };
        } catch (error) {
            console.error("Error in signInWithEmailPassword:", error);
            return { success: false, error: error.message };
        }
    };
};

export const resetPassword = (email) => {
    return async (dispatch) => {
        try {
            await sendPasswordResetEmail(auth, email);
            return { success: true };
        } catch (error) {
            console.error("Error sending password reset email:", error);
            return { success: false, error: error.message };
        }
    };
};
import React, { useState, useEffect } from 'react';
import settingsIcon from '../images/settings01.png';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTenants, addTenant, changeTenant } from '../redux/actions';

const ManageTargets = () => {
    const logoURL = useSelector((state) => state.logoURL);
    const dispatch = useDispatch();
    const tenants = useSelector((state) => state.tenants);
    console.log('Current tenants:', tenants);
    const [newTenant, setNewTenant] = useState({ name: '', tenantID: '', logo: '', orgID: '', clientAccountId: '' });
    const [showForm, setShowForm] = useState(false);
    const userTenant = useSelector((state) => state.userTenant);

    useEffect(() => {
        dispatch(fetchTenants());
    }, [dispatch]);

    const handleAddTenant = () => {
        if (newTenant.name.trim()) {
            dispatch(addTenant(newTenant));
            setNewTenant({ name: '', tenantID: '', logo: '', orgID: '', clientAccountId: '' });
        }
    };

    const handleShowForm = () => {
        setShowForm(true);
    };

    const handleSwitchTenant = (tenantId) => {
        console.log('Switching to tenant:', tenantId);
        dispatch(changeTenant(tenantId));
    };

    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <div className="w-full flex items-center justify-between p-2 bg-gray-100" style={{ height: '75px' }}>
                    <Link to="/">
                        <img src={logoURL} alt="Logo" className="h-15" style={{ width: "auto", height: '60px' }} />
                    </Link>

                    <div className="absolute left-1/2 transform -translate-x-1/2">

                    </div>
                    <Link to="/settings" className="h-6 w-6">
                        <img src={settingsIcon} alt="Settings" className="h-full w-full" />
                    </Link>
                </div>
                <h1>Manage Tenants & Targets</h1>

                <div className="w-full mt-8">
                    <h2 className="text-xl font-bold mb-4">Tenants:</h2>
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <th className="py-2">Name</th>
                                <th className="py-2">Tenant ID</th>
                                <th className="py-2">Logo</th>
                                <th className="py-2">Org ID</th>
                                <th className="py-2">Client Account ID</th>
                                <th className="py-2">Switch Tenant</th>
                                <th className="py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tenants.map((tenant) => (
                                <tr key={tenant.id} className={`text-center ${tenant.id === userTenant ? 'bg-blue-100' : ''}`}>
                                    <td className="py-2">{tenant.tenantName}</td>
                                    <td className="py-2">{tenant.id}</td>
                                    <td className="py-2"><img src={tenant.logo} alt="Logo" className="h-10 mx-auto" /></td>
                                    <td className="py-2">{tenant.org_id}</td>
                                    <td className="py-2">{tenant.clientAccountId}</td>
                                    <td className="py-2">
                                        {tenant.id !== userTenant && (
                                            <button
                                                onClick={() => handleSwitchTenant(tenant.id)}
                                                className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                                            >
                                                Switch
                                            </button>
                                        )}
                                    </td>
                                    <td className="py-2">
                                        <Link to={`/tenant/${tenant.id}`} className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600">
                                            Edit
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <button onClick={handleShowForm} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
                            + Add New Tenant
                        </button>
                        {showForm && (
                            <div className="mt-2">
                                <input
                                    type="text"
                                    value={newTenant.name}
                                    onChange={(e) => setNewTenant({ ...newTenant, name: e.target.value })}
                                    className="px-3 py-2 border rounded w-full"
                                    placeholder="Enter tenant name"
                                />
                                <input
                                    type="text"
                                    value={newTenant.tenantID}
                                    onChange={(e) => setNewTenant({ ...newTenant, tenantID: e.target.value })}
                                    className="px-3 py-2 border rounded w-full mt-2"
                                    placeholder="Enter tenant ID"
                                />
                                <input
                                    type="text"
                                    value={newTenant.logo}
                                    onChange={(e) => setNewTenant({ ...newTenant, logo: e.target.value })}
                                    className="px-3 py-2 border rounded w-full mt-2"
                                    placeholder="Enter logo URL"
                                />
                                <input
                                    type="text"
                                    value={newTenant.orgID}
                                    onChange={(e) => setNewTenant({ ...newTenant, orgID: e.target.value })}
                                    className="px-3 py-2 border rounded w-full mt-2"
                                    placeholder="Enter org ID"
                                />
                                <input
                                    type="text"
                                    value={newTenant.clientAccountId}
                                    onChange={(e) => setNewTenant({ ...newTenant, clientAccountId: e.target.value })}
                                    className="px-3 py-2 border rounded w-full mt-2"
                                    placeholder="Enter client account ID"
                                />
                                <button onClick={handleAddTenant} className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                                    Add Tenant
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageTargets; 
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers'; // Import your root reducer
const logger = createLogger();
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk, logger),
});

export default store;